<template>
  <div class="home">
    <div class="heading-text">
      <h1>The global community for telecom industry</h1>
    </div>
    <b-container class="home-container">
      <div class="info">
        <div>
          <img
            class="home-img"
            src="../../assets/icon/01.png"
            alt=""
          >
          <p>
            CC organizes annual global member events. Click the below button Events  to view the forthcoming events.
          </p>
          <router-link to="/all-events">
            <button>Events</button>
          </router-link>
        </div>
      </div>
      <div v-if="!isLoggedIn" class="info">
        <div>
          <img
            id="home-img"
            src="../../assets/icon/02.png"
            alt="">
          <p >
            To become a CC member please click the Register button to fill in the online form.
          </p>
          <router-link to="/sign-up">
            <button >Register</button>
          </router-link>
        </div>
      </div>
      <div class="info">
        <div>
          <img
            class="home-img"
            src="../../assets/icon/03.png"
            alt=""
          >
          <p>
            To learn more about the Carrier Community (CC) and its member portals, please click the below button About.
          </p>
          <a href="https://public.carriercommunity.com/about-us/" target="_self">
            <button>About</button>
          </a>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Homepage',
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
};
</script>
<style scoped>
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-img {
  width: unset !important;
}
#home-img {
  width: unset !important;
  margin-right: 23px;
}
.home-container {
  display: flex;
  justify-content: space-around;
  max-width: 800px;
  margin: 0 auto;
  background-color: #ffffffb7;
}
.heading-text {
  text-align: center;
  width: 60rem;
  max-width: 80%;
  margin-top: 2rem;
  text-transform: uppercase;
}
.heading-text > h1 {
  font-size: 40px;
  line-height: 41px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
}
.info {
  text-align: center;
}
.info > div {
  padding: 30px 8px;
}
.info > div > p {
  margin-top: 0;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 15px;
  color: #333333;
}
button {
  width: 81px;
  background: #BD9D5F;
  border-radius: 3px;
  border: none;
  color: #fff;
  padding: 2px;
}
i {
  font-size: 66px;
  color: #bd9d5e;
}
 @media (max-width: 630px) {
  .home-container {
    height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
  }
  img {
    width: unset;
    height: 50px;
  }
.info{
  width: 50%;
}
  .info > div {
    padding-top: 5px;
  }
  button {
    width: 64px;
    background: #BD9D5F;
    border-radius: 3px;
    border: none;
    color: #fff;
    padding: 2px;
  }
}
@media (max-width: 768px){
  .heading-text > h1 {
    font-size: 30px;
  }
  .heading-text {
    margin-top: 1rem;
  }
}
@media (max-width: 450px){
  .heading-text > h1 {
    font-size: 20px;
  }
}

</style>
